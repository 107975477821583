import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateUtil } from '@utils/classes/DateUtil';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { Observable, of } from 'rxjs';
import { DateFormat } from '~models/Util';
import { ScaRoutingEstadoCustom } from '~models/carga/interface';
import { ScaRoutingEstadoService } from '~services/carga';

@Component({
	selector: 'app-historial-estado',
	templateUrl: './historial-estado.component.html',
})
export class HistorialEstadoComponent implements OnInit {
	rowData: Observable<ScaRoutingEstadoCustom[]> = of([]);
	private _dateUtil = new DateUtil();
	columnDefs: ColDef[] = [];
	nroCotizacion: UntypedFormControl = new UntypedFormControl('');
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { idRouting: number; nroRouting: string },
		private dialogRef: MatDialogRef<HistorialEstadoComponent>,
		private readonly _scaRoutingEstadoService: ScaRoutingEstadoService
	) {}

	ngOnInit(): void {
		console.log(this.data);
		this.createColumnDefs();
		this.rowData = this._scaRoutingEstadoService.findAllByIdRouting(this.data.idRouting);
		this.nroCotizacion.patchValue(this.data.nroRouting);
		this.nroCotizacion.disable();
	}

	createColumnDefs(): void {
		this.columnDefs = [
			{
				headerName: 'Estado',
				field: 'estado',
				width: 140,
			},
			{
				headerName: 'Fecha y Hora',
				field: 'fchRegistro',
				width: 160,
				valueGetter: (params: ValueGetterParams): string => (params.data.fchRegistro ? this._dateUtil.formatDate(params.data.fchRegistro, DateFormat.DATE_HH_MM) : ''),
			},
			{
				headerName: 'Usuario',
				field: 'usuario',
				width: 260,
			},
		];
	}

	close(): void {
		this.dialogRef.close();
	}
}
